import { AtomBox } from "@gemlaunch/ui";
import { ReactNode } from "react";
import { CSSProperties } from "styled-components";
import { Heading } from "../../components/Heading";
import { Text } from "../../components/Text";

interface Props {
  title: ReactNode;
  subtitle: ReactNode;
}

export const CurrencyInputHeader = ({ title, subtitle }: Props) => {
  return (
    <AtomBox width="full" alignItems="center" flexDirection="column"
      style={{ padding: "1rem 2rem 1rem 2rem" }}>
      <AtomBox display="flex" width="full" alignItems="center" justifyContent="space-between">
        {title}
      </AtomBox>
      {subtitle}
    </AtomBox>
  );
};

export const CurrencyInputHeaderTitle = ({ children, style }: { children: ReactNode, style?: CSSProperties }) => (
  <Heading as="h2" style={style}>{children}</Heading>
);
export const CurrencyInputHeaderSubTitle = ({ children, style }: { children: ReactNode, style?: CSSProperties }) => (
  <Text color="textSubtle" fontSize="14px" textAlign="center" style={style}>
    {children}
  </Text>
);
