


import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  const { theme } = props
  return (
    <Svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M37.1367 26V10C37.1367 4.47715 32.6596 0 27.1367 0L9.99972 0C4.47687 0 -0.000282288 4.47715 -0.000282288 10V26C-0.000282288 31.5228 4.47687 36 9.99972 36H27.1367C32.6596 36 37.1367 31.5228 37.1367 26Z" fill="#090909" />
      <path d="M13.9268 27L13.2308 27.718L13.9268 28.393L14.6228 27.718L13.9268 27ZM16.2478 16.75C16.5131 16.75 16.7674 16.6446 16.955 16.4571C17.1425 16.2696 17.2478 16.0152 17.2478 15.75C17.2478 15.4848 17.1425 15.2304 16.955 15.0429C16.7674 14.8554 16.5131 14.75 16.2478 14.75V16.75ZM8.58984 23.218L13.2318 27.718L14.6238 26.282L9.98184 21.782L8.58984 23.218ZM14.6238 27.718L19.2668 23.218L17.8748 21.782L13.2318 26.282L14.6238 27.718ZM14.9278 27V17.75H12.9278V27H14.9278ZM15.9278 16.75H16.2478V14.75H15.9288L15.9278 16.75ZM14.9278 17.75C14.9278 17.4848 15.0332 17.2304 15.2207 17.0429C15.4083 16.8554 15.6626 16.75 15.9278 16.75L15.9288 14.75C15.1332 14.75 14.3691 15.0661 13.8065 15.6287C13.2439 16.1913 12.9278 16.9543 12.9278 17.75H14.9278ZM23.2118 8.99999L22.5148 8.28199L23.2118 7.60699L23.9078 8.28199L23.2118 8.99999ZM20.8898 21.25C20.6246 21.25 20.3703 21.1446 20.1827 20.9571C19.9952 20.7696 19.8898 20.5152 19.8898 20.25C19.8898 19.9848 19.9952 19.7304 20.1827 19.5429C20.3703 19.3554 20.6246 19.25 20.8898 19.25V21.25ZM17.8728 12.782L22.5148 8.28199L23.9078 9.718L19.2658 14.218L17.8728 12.782ZM23.9078 8.28199L28.5498 12.782L27.1578 14.218L22.5158 9.718L23.9078 8.28199ZM24.2118 8.99999V18.25H22.2118V8.99999H24.2118ZM21.2118 21.25H20.8898V19.25H21.2118V21.25ZM24.2118 18.25C24.2118 19.0456 23.8958 19.8087 23.3332 20.3713C22.7706 20.9339 22.0075 21.25 21.2118 21.25V19.25C21.4771 19.25 21.7314 19.1446 21.919 18.9571C22.1065 18.7696 22.2118 18.5152 22.2118 18.25H24.2118Z"
        fill={theme?.colors.primary} />
    </Svg>
  );
};

export default Icon;
