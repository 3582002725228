import styled from "styled-components";
import { ButtonProps, IconButton } from "../../components/Button";
import { ArrowDownIcon, ArrowUpDownIcon, SwitchIcon } from "../../components/Svg";
import { switchButtonClass, iconDownClass, iconUpDownClass } from "./SwapWidget.css";
import { CurrencyInputPanel } from "./CurrencyInputPanel";
import { CurrencyInputHeader, CurrencyInputHeaderSubTitle, CurrencyInputHeaderTitle } from "./CurrencyInputHeader";
import { SwapPage } from "./Page";
import { SwapFooter } from "./Footer";
import { SwapInfo, SwapInfoLabel } from "./SwapInfo";
import { TradePrice } from "./TradePrice";
import { Box } from "../../components";


const RotateOnHover = styled(IconButton)`
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: rotate(180deg);
  }
`;

const SwapSwitchButton = (props: ButtonProps) => (
  <IconButton className={switchButtonClass} variant="light" scale="sm" {...props}>
    {/* <RotateOnHover className={switchButtonClass} variant="light" scale="sm" {...props}> */}
    <SwitchIcon className={iconDownClass} color="primary" />
    {/* </RotateOnHover> */}
    {/* <ArrowDownIcon className={iconDownClass} color="primary" />
    <ArrowUpDownIcon className={iconUpDownClass} color="primary" /> */}
  </IconButton>
);

const Swap = {
  SwitchButton: SwapSwitchButton,
  CurrencyInputHeaderTitle,
  CurrencyInputHeaderSubTitle,
  CurrencyInputHeader,
  CurrencyInputPanel,
  Page: SwapPage,
  Footer: SwapFooter,
  Info: SwapInfo,
  InfoLabel: SwapInfoLabel,
  TradePrice,
};

export { Swap };
