import { useTranslation } from "@gemlaunch/localization";
import { useIsMounted } from "@gemlaunch/hooks";
import { PropsWithChildren, ReactNode, useState } from "react";
// import { escapeRegExp } from 'utils'
// import { useUserSlippageTolerance } from 'state/user/hooks'
import { AutoColumn, RowBetween, Text, TextProps, IconButton, PencilIcon, Flex, QuestionHelper, Button, Box, Input } from "../../components";

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}


type SwapInfoType = {
  price: ReactNode;
  allowedSlippage: number;
  setAllowSlippage?: (slippage: number) => void;
  onSlippageClick?: () => void;
  allowedSlippageSlot?: React.ReactNode;
  ttl?: number;
  setTtl?: (slippage: number) => void
};

export const SwapInfoLabel = (props: PropsWithChildren<TextProps>) => (
  <Text fontSize="16px" color="textSubtle" {...props} />
);

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export const SwapInfo = ({ allowedSlippage, setAllowSlippage, price, onSlippageClick, allowedSlippageSlot, ttl, setTtl }: SwapInfoType) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [slippageInput, setSlippageInput] = useState('')

  const slippageInputIsValid =
    slippageInput === '' || (allowedSlippage / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)


  const parseCustomSlippage = (value: string) => {
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      setSlippageInput(value)

      try {
        const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
        if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
          if (setAllowSlippage) {
            setAllowSlippage(valueAsIntFromRoundedFloat)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && allowedSlippage < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && allowedSlippage > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }


  return (
    <AutoColumn gap="sm" py="0" px="16px" style={{ marginTop: '8px' }}>
      <RowBetween alignItems="center">

        {
          setAllowSlippage &&
          <Flex flexDirection="column" width="100%">
            <Flex mb="12px" justifyContent="space-between" width="100%">
              <Flex>
                <Text>{t('Slippage Tolerance')}</Text>
                <QuestionHelper
                  text={t(
                    'Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.',
                  )}
                  placement="top"
                  ml="4px"
                />
              </Flex>
              {isMounted &&
                (allowedSlippageSlot ?? (
                  <Text bold color="primary">
                    {allowedSlippage / 100}%
                  </Text>
                ))}
            </Flex>

            <Flex>
              <Button
                mt="4px"
                mr="4px"
                scale="sm"
                style={{ borderRadius: '20px' }}
                onClick={() => {
                  setSlippageInput('')
                  setAllowSlippage(10)
                }}
                variant={allowedSlippage === 10 ? 'primary' : 'tertiary'}
              >
                0.1%
              </Button>
              <Button
                mt="4px"
                mr="4px"
                scale="sm"
                style={{ borderRadius: '20px' }}
                onClick={() => {
                  setSlippageInput('')
                  setAllowSlippage(50)
                }}
                variant={allowedSlippage === 50 ? 'primary' : 'tertiary'}
              >
                0.5%
              </Button>
              <Button
                mr="4px"
                mt="4px"
                scale="sm"
                style={{ borderRadius: '20px' }}
                onClick={() => {
                  setSlippageInput('')
                  setAllowSlippage(100)
                }}
                variant={allowedSlippage === 100 ? 'primary' : 'tertiary'}
              >
                1.0%
              </Button>
              <Button
                mr="4px"
                mt="4px"
                scale="sm"
                style={{ borderRadius: '20px' }}
                onClick={() => {
                  setSlippageInput('')
                  setAllowSlippage(200)
                }}
                variant={allowedSlippage === 200 ? 'primary' : 'tertiary'}
              >
                2.0%
              </Button>
              <Flex alignItems="center">
                <Box width="76px" mt="4px">
                  <Input
                    scale="sm"
                    style={{
                      borderRadius: '20px',
                      border: '1px solid lightgreen'}}
                    
                    inputMode="decimal"
                    pattern="^[0-9]*[.,]?[0-9]{0,2}$"
                    placeholder={(allowedSlippage / 100).toFixed(2)}
                    value={slippageInput}
                    onBlur={() => {
                      parseCustomSlippage((allowedSlippage / 100).toFixed(2))
                    }}
                    onChange={(event) => {
                      if (event.currentTarget.validity.valid) {
                        parseCustomSlippage(event.target.value.replace(/,/g, '.'))
                      }
                    }}
                    isWarning={!slippageInputIsValid}
                    isSuccess={![10, 50, 100].includes(allowedSlippage)}
                  />
                </Box>
                <Text color="primary" bold ml="2px">
                  %
                </Text>
              </Flex>
            </Flex>
            {!!slippageError && (
              <Text fontSize="14px" color={slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E'} mt="8px">
                {slippageError === SlippageError.InvalidInput
                  ? t('Enter a valid slippage percentage')
                  : slippageError === SlippageError.RiskyLow
                    ? t('Your transaction may fail')
                    : t('Your transaction may be frontrun')}
              </Text>
            )}
          </Flex>
        }

      </RowBetween>
      <RowBetween alignItems="center">{price}</RowBetween>

    </AutoColumn>
  );
};
